/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body')

        $body.on('loaderEnd', () => console.log('ended'))

        /*
        |
        | Scroll to anchor
        |-----------------
        */
        if ($(".down").length) {
            jQuery('a[href*=\\#]').click(function (event) {
                $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top - 80
                }, 1000);
                event.preventDefault();
            });
        }

        /*
        |
        | Bouton acces-client
        |-----------
        */
        jQuery(document).ready(function ($) {
            jQuery('.acces-client').on('click', function () {
                jQuery('.closebtn').fadeIn(400);
                jQuery('#blockaccesclient').addClass('active');
            });
            jQuery('.closebtn').on('click', function () {
                jQuery('.closebtn').fadeOut(400);
                jQuery('#blockaccesclient').removeClass('active');
            });
        });

        /*
        |
        | Swiper slides chronologie
        |-----------
        */
        let swiperslideschronologie = new Swiper("#swiper-slideschronologie", {
            autoHeight: true,
            navigation: {
                nextEl: '.chrono-button-next',
                prevEl: '.chrono-button-prev'
            },
            slidesPerView: 6,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 6,
                }
            }
        });

        /*
        |
        | Bouton acces-client
        |-----------
        */
        jQuery(document).ready(function ($) {
            jQuery('.acces-client').on('click', function () {
                jQuery('.closebtn').fadeIn(400);
                jQuery('#blockaccesclient').addClass('active');
            });
            jQuery('.closebtn').on('click', function () {
                jQuery('.closebtn').fadeOut(400);
                jQuery('#blockaccesclient').removeClass('active');
            });
        });

        /*
        |
        | Swiper rejoindre and address
        |-----------
        */
        const swiperrejoindre = new Swiper("#swiper-rejoindre", {
            spaceBetween: 0,
            autoHeight: true,
            navigation: {
                nextEl: '.rejoindre-button-next',
                prevEl: '.rejoindre-button-prev'
            }
        });
        const swiperaddress = new Swiper("#swiper-address", {
            watchSlidesVisibility: true,
            slidesPerView: 1,
            breakpoints: {
                0: {
                    centeredSlides: true,
                    disableOnInteraction: true,
                    slidesPerView: 1
                },
                992: {
                    centeredSlides: true,
                    disableOnInteraction: true,
                    slidesPerView: 1
                },
                1200: {
                    slidesPerView: 1.2
                }
            }
        });

        $(window).on('resize', function () {
            swiperaddress.update();
        });

        $(window).resize(function () {
            swiperaddress.update();
        })

        /*
        |
        | Swiper études de cas
        |-----------
        */
        const swiperedc = new Swiper("#swiper-edc", {
            direction: 'horizontal',
            loop: true,
            spaceBetween: 30,
            autoHeight: true, //enable auto height
            navigation: {
                nextEl: '.edc-button-next',
                prevEl: '.edc-button-prev'
            }
        });


        swiperrejoindre.on('activeIndexChange', function () {
            let indexslide = jQuery($body).find('#swiper-rejoindre').attr('data-totalslide');

            if (swiperrejoindre.activeIndex >= indexslide) {
                swiperaddress.slideNext();
            }
            if (swiperrejoindre.activeIndex < indexslide) {
                swiperaddress.slidePrev();
            }
        })

        /*
        |
        | Modal team
        |-----------------
        */
        if ($(".iziModal").length) {
            $(".iziModal").iziModal({
                closeButton: true,
                radius: 100,
                headerColor: '#011A24',
                transitionIn: 'fade',
                bodyOverflow: true
            });
        }


        /*
        |
        | Select Accompagnement
        |-------------------
        */
        if ($(".accomp-select").length) {
            $('.accomp-select').on('click', function () {
                $(this).toggleClass('active')
            });

            $('.accomp-select ul li').on('click', function () {
                let v = $(this).text();
                $('.accomp-select ul li').not($(this)).removeClass('active');
                $(this).addClass('active');
                $('.accomp-select label button').text(v)
            });

            $('.item-accomp').on('click', function () {
                let idtext = $(this).attr('data-accomp');
                $('.accomp-text .text').removeClass('active').slideUp();
                $('.' + idtext).addClass('active').slideDown();
            });

            $('.accomp-select button').on('DOMSubtreeModified', function () {
                $(this).addClass('active');
            });
        }

        /*
        |
        | Cercle des métiers Desktop
        |-------------------
        */
        if ($(".circle").length) {

            jQuery(".circle .label").on("mouseover", function () {
                jQuery($body).find('.icon').addClass('hover');
            });

            jQuery(".label").click(function () {
                var dataId = $(this).data("id");
                $(".block-item-content .item-content").removeClass("select");
                $(".block-item-content .item-" + dataId).addClass("select");

                $(".block-item-content").addClass("active");

                jQuery($body).find('.label').removeClass('active');

                jQuery($body).find('.icon img').attr("src", $(this).attr('data-img'));
                $(this).addClass('active');
            });
        }

        /*
        |
        | Cercle des métiers Mobile
        |-----------------
        */
        $(document).ready(function () {
            $('.block__title').click(function (event) {
                if ($('.block').hasClass('one')) {
                    $('.block__title').not($(this)).removeClass('active');
                    $('.block__text').not($(this).next()).slideUp(300);
                }
                $(this).toggleClass('active').next().slideToggle(300);
            });

        });


        /*
        |
        | Mouse transform
        |-----------------
        */
        window.addEventListener('DOMContentLoaded', () => {
            const mouse_circle = document.getElementById('mouse_circle');
            document.addEventListener('mousemove', (e) => {
                mouse_circle.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';
            });
            jQuery(".mousezone").mouseover(function () {
                jQuery('.mouse_circle').addClass('moused');

            });
            jQuery(".mousezone").mouseout(function () {
                jQuery('.mouse_circle').removeClass('moused');
            });

            jQuery(".edc-button-next").mouseover(function () {
                jQuery('.mouse_circle').addClass('mousenext');
            });
            jQuery(".edc-button-next").mouseout(function () {
                jQuery('.mouse_circle').removeClass('mousenext');
            });
        });

        /*
        |
        | Chiffres
        |-----------------
        */

        if ($(".block-chiffres").length) {
            let a = 0;
            $(window).scroll(function () {
                let oTop = $('.block-chiffres').offset().top - window.innerHeight;

                if (a == 0 && $(window).scrollTop() > oTop) {
                    $('.counterUp').each(function () {
                        let $this = $(this),
                            countTo = $this.attr('data-count');
                        $({
                            countNum: $this.text()
                        }).animate({
                            countNum: countTo
                        },
                            {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                    //alert('finished');
                                }

                            });
                    });
                    a = 1;
                }
            });
        }

        /*
        |
        | Sub-menu
        |-----------------
        */
        $(document).ready(function () {
            function toggleMenu(e) {
                e.preventDefault();
                $('.nav-ssmenu-drop').slideToggle('fast');
                $(this).toggleClass('active');
            }

            if ($(window).width() > 1200) {
                $('.menu-item-has-children').click(toggleMenu);
            }

            $(window).resize(function () {
                if ($(window).width() > 1200) {
                    $('.menu-item-has-children').click(toggleMenu);
                } else {
                    $('.menu-item-has-children').off('click', toggleMenu);
                }
            });
        });


    }
}

